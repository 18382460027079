//@ts-check
import { IFExistsResponse } from '@/views/services/api-caller';
import { getGlobalSettings } from './global-settings';

export const FILEEE_DOMAINS = new Set([
  'https://beta.workeee.de',
  'https://my.workeee.de',
  'https://beta.fileee.com',
  'https://my.fileee.com',
  'http://localhost:9000'
]);

export const FILEEE_TEST_DOMAINS = new Set(['https://beta.workeee.de', 'https://my.workeee.de']);
const FILEEE_BETA_DOMAINS = new Set(['https://beta.fileee.com']);

export function isTestEnv() {
  const baseDomain = getBaseDomainUrl();

  if (FILEEE_TEST_DOMAINS.has(baseDomain)) {
    return true;
  }
  if (__DEV__) {
    return true;
  }
  return false;
}

export function isBetaEnv() {
  const baseDomain = getBaseDomainUrl();
  return FILEEE_BETA_DOMAINS.has(baseDomain);
}

export function getExternalLoggedInUserId() {
  return getGlobalSettings().externalUserId;
}

export function getExternalLoggedInUsername() {
  return getGlobalSettings().externalUsername || '';
}

export function clearCookie(name) {
  document.cookie = `${name}=;max-age=-1; path=/`;
}

export function addUpdateCookie({ name, value, domain, maxAge }) {
  document.cookie = `${name}=${value};domain=${domain};max-age=${maxAge}; path=/`;
}

export function clearDomainSpecificCookie(name, domain) {
  if (window.getCookieValue(name)) {
    document.cookie = `${name} = ; domain = ${domain}; max-age=-1; path=/`;
  }
}

export function clearFileeeDomainCookie(name) {
  if (window.getCookieValue(name)) {
    const domain = isTestEnv() ? '.workeee.de' : '.fileee.com';
    clearDomainSpecificCookie(name, domain);
  }
}

const STATIC_DOMAIN_WORKEEE_BASE = 'https://static.workeee.de/';
const STATIC_DOMAIN_FILEEEE_BASE = 'https://static.fileee.com/';

/**
 * helper to set cookie
 * @export
 * @param {string} name
 * @param {string} value
 * @returns
 */
export function setCookie(name, value) {
  if (!name) {
    return;
  }
  if (value === null || typeof value === 'undefined') {
    document.cookie = `${name}=;expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  } else {
    document.cookie = `${name}=${value};expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  }
}

export function setCookieWithDomain(name, value, domain) {
  if (__DEV__) {
    console.log(` You are in dev environment. 
    So we will not set domain cookie as safari and firefox will break with localhost domain cookie`);
    // document.cookie = `${name}=${value};domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  } else {
    document.cookie = `${name}=${value};domain=${domain};expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
  }
}

/**
 *
 * @export
 * @param {string} userId
 */
export function setExternalLogIn(userId, username) {
  setCookie('main_user', userId);
  window.dispatchEvent(new CustomEvent('UserLoggedIn', { detail: userId }));
  document.fileeeBasicVar.userId = userId;
  document.fileeeBasicVar.actingUserId = null;
  document.fileeeBasicVar.externalUserId = userId;
  document.fileeeBasicVar.externalUsername = username;
}

export function logOutExternalUser() {
  document.cookie = 'main_user=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  document.fileeeBasicVar.externalUserId = null;
}

export function getTimeLoadingSinceStartup() {
  return window.getTimeLoadingSinceStartup();
}

/**
 * @returns {string}
 */
export function getBaseDomainUrl() {
  return window.getBaseDomainUrl();
}

export function isProductionEnv() {
  return (
    document.fileeeBasicVar.runtimeEnv === 'live' ||
    document.fileeeBasicVar.runtimeEnv === 'release_candidate'
  );
}

export function isLiveEnv() {
  return document.fileeeBasicVar.runtimeEnv === 'live';
}

export function getStaticDomainBase() {
  return isProductionEnv() ? STATIC_DOMAIN_FILEEEE_BASE : STATIC_DOMAIN_WORKEEE_BASE;
}

export function getBaseAPIUrl() {
  return document.fileeeBasicVar.baseAPIUrl ? document.fileeeBasicVar.baseAPIUrl : '';
}

export function isLoggedIn() {
  return window.isLoggedIn();
}

export function isExternalLoggedIn() {
  return !window.getCookieValue('main_user');
}

export function isExternalSession(existsResponse: IFExistsResponse) {
  if (!existsResponse) return false;

  if (existsResponse.user && existsResponse.user.type === 'EXTERNAL') {
    return true;
  }

  return false;
}

const SESSION_SCOPE_FULL = 'fileee:full';
const SESSION_SCOPE_EXTERNAL_CONVERSATION = 'fileee:conversation:external:';

export function hasFullAccessScope(existsResponse: IFExistsResponse) {
  if (!existsResponse) return false;

  if (existsResponse.scopes && existsResponse.scopes.includes(SESSION_SCOPE_FULL)) {
    return true;
  }

  return false;
}

export function isExternalConversationSession(existsResponse: IFExistsResponse) {
  if (!existsResponse) return false;

  if (
    existsResponse.user &&
    existsResponse.user.type === 'EXTERNAL' &&
    existsResponse.scopes &&
    existsResponse.scopes.length > 0
  ) {
    return existsResponse.scopes.some((scope) =>
      scope.startsWith(SESSION_SCOPE_EXTERNAL_CONVERSATION)
    );
  }

  return false;
}

export function getExternalConversationIdsFromSessionScope(existsResponse: IFExistsResponse) {
  if (!existsResponse) return [];

  if (!isExternalConversationSession(existsResponse)) {
    return [];
  }

  return existsResponse.scopes
    .filter((scope) => scope.startsWith(SESSION_SCOPE_EXTERNAL_CONVERSATION))
    .map((scope) => scope.replace(SESSION_SCOPE_EXTERNAL_CONVERSATION, ''))
    .filter(Boolean);
}
