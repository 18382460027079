/**
 * When user comes to webapp via urls which contains tokens
 * We need to disable tracking urls as this will leak token information to external services
 */

const SenstivePaths = ['/new-password'];

let startedOnSensitivePath = false;

let startUrlSearchParams: URLSearchParams = new URLSearchParams();

export function storeStartUrlInfo() {
  let pathname = window.location.pathname;

  startedOnSensitivePath = SenstivePaths.some((item) => pathname.startsWith(item));
  startUrlSearchParams = new URLSearchParams(window.location.search);
}

export function isUrlTrackingDisabled() {
  return startedOnSensitivePath;
}

export function getStartUrlSearchParams() {
  return startUrlSearchParams;
}
