export const ReactQueryKeys = {
  team: 'team',
  teamMember: 'team-member',
  teamMembers: 'team-members',
  userSession: 'user-session',
  storeAddon: 'store-add-on',
  nextInvoice: 'next-invoice',
  teamMemberNextInvoice: 'team-member-next-invoice',
  paymentDetailsLink: 'payment-details-link',
  companyActions: 'company-actions',
  dashboardCompanyActions: 'dashboard-company-actions',
  authenticatedUserAction: 'authenticated-user-action',
  multiEditGatherTags: 'multi-edit-gather-tags',
  multiEditCheckRequests: 'multi-edit-check-requests',
  deletedDocuments: 'deleted-documents',
  documentHistory: 'document-history',
  kmmForeignAccounts: 'kmm-foreign-accounts',
  taxAccounts: 'tax-accounts',
  foreignAccounts: 'foreign-accounts',
  syncAccountCount: 'sync-account-count',
  futurePaymentInfo: 'future-payment-info',
  taxAccountStatus: 'tax-account-status',
  documentMemberAccess: 'document-member-acces',
  auditStatisticsInfoAllTime: 'auditStatisticsInfoAllTime',
  auditStatisticsInfoLast30Days: 'auditStatisticsInfoLast30Days',
  wordsForPage: 'words-for-page',
  thinkowlEntities: 'thinkowl-entities',
  fileLoading: 'file-loading',
  fceActionLinkAndImage: 'fce-action-link-and-image',
  userCompanyConnection: 'user-company-connection',
  externalDropdownSource: 'external-dropdown-source',

  existingConversationsForAction: 'existing-conversations-for-action',
  endUserSingleAction: 'end-user-single-action',
  startup: {
    fStart: 'startup-f-start',
    fExists: 'startup-f-exists'
  }
};
