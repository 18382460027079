import { getLocationPathName } from '@/common/location-utils';
import { getQueryClient } from '@/common/query-client';
import {
  EXTERNAL_ACTION_CONVERSATIONS_LIBRARY,
  EXTERNAL_COMMUNICATION_BY_TOKEN,
  USER_ACTIONS
} from '@/common/route-constants';
import { ReactQueryKeys } from '@/constants/react-query-keys';

import { isLoggedIn, setCookieWithDomain } from 'common/utils';
import { getLogger } from 'logger';
import { DOMAIN_COOKIE } from 'views/common/utils/constants';
import { getWildcardDomain } from 'views/common/utils/general';
import { getFExistsQueryConfig, getFStartQueryConfig } from './startup-queries';

const logger = getLogger('setup-cookies');

function fetchFStartQuery() {
  return getQueryClient().fetchQuery(getFStartQueryConfig());
}

function fetchFExistsQuery() {
  return getQueryClient().fetchQuery(getFExistsQueryConfig());
}

export async function setupCookies() {
  // for cross domain requests to work, we need to set cookie on wild card sub domain
  const hostname = encodeURI(window.location.hostname);
  const wildCardDomain = getWildcardDomain(hostname);
  setCookieWithDomain(DOMAIN_COOKIE, hostname, wildCardDomain);

  const pathname = getLocationPathName();

  if (
    isLoggedIn() ||
    pathname.startsWith(EXTERNAL_ACTION_CONVERSATIONS_LIBRARY.startPath) ||
    pathname.startsWith(EXTERNAL_COMMUNICATION_BY_TOKEN.startPath) ||
    pathname.startsWith(USER_ACTIONS.startPath)
  ) {
    await getQueryClient().fetchQuery(getFStartQueryConfig());
    return getQueryClient().fetchQuery(getFExistsQueryConfig());
  } else {
    // need to make atleast one get request, so that we can get xsrf cookie set by the server before loading the application
    return getQueryClient().fetchQuery(getFStartQueryConfig());
  }
}
