import { setLoggedOut } from '@/common/login-logout-utils';
import { ReactQueryKeys } from '@/constants/react-query-keys';
import { getLogger } from '@/logger';
import { getFExists, getFStart, IFExistsResponse } from '@/views/services/api-caller';
import { AxiosError } from 'axios';
import { FetchQueryOptions } from 'react-query';

const logger = getLogger('startup-queries');

function fExistsQueryFn(): Promise<IFExistsResponse | null> {
  return getFExists()
    .then((response) => {
      let errorCode = response.status;
      if (response.status === 200) {
        return response.data;
      }
      if (errorCode === 501 || errorCode === 502 || errorCode === 503 || errorCode === 504) {
        console.log('[IN] Server not reachable ' + errorCode + ' we continue as logged in anyway');
      } else if (errorCode === 401 || errorCode == 403) {
        console.log('[IN] Not logged in anymore');
        setLoggedOut();
      } else {
        logger.error('Exception which checking auth status', response.status);
        setLoggedOut();
      }
      return null;
    })
    .catch((reason: AxiosError) => {
      const errorCode = reason.response && reason.response.status;
      if (errorCode === 501 || errorCode === 502 || errorCode === 503 || errorCode === 504) {
        console.log('[IN] Server not reachable ' + errorCode + ' we continue as logged in anyway');
      } else if (errorCode === 401 || errorCode == 403) {
        console.log('[IN] Not logged in anymore');
      } else {
        logger.error('Exception which checking auth status', errorCode);
      }
      setLoggedOut();
      return null;
    });
}

export function getFExistsQueryConfig(): FetchQueryOptions<unknown, unknown, IFExistsResponse> {
  return {
    queryKey: [ReactQueryKeys.startup.fExists],
    queryFn: fExistsQueryFn,
    /**
     * 2 mins
     */
    staleTime: 2 * 60 * 1000
  };
}

function fStartQueryFn(): Promise<string> {
  return getFStart()
    .then(() => {
      return 'success';
    })
    .catch(() => {
      logger.error(`Failed to make fStart query`);
      return 'failed';
    });
}

export function getFStartQueryConfig(): FetchQueryOptions<unknown, unknown, string> {
  return {
    queryKey: [ReactQueryKeys.startup.fStart],
    queryFn: fStartQueryFn,
    staleTime: Infinity
  };
}
