export const DASHBOARD = { name: 'DASHBOARD', route: '/dashboard' };
export const DASHBOARD_NEW_DOCUMENT = { name: 'DASHBOARD_NEW_DOCUMENT', router: 'new-document' };
export const DOCUMENT_LIBRARY = { name: 'DOCUMENT_LIBRARY', route: '/documents' };
export const SINGLE_DOCUMENT = { name: 'SINGLE_DOCUMENT', route: 'documents/:id' };

export const SINGLE_DOCUMENT_OVERVIEW = {
  name: 'SINGLE_DOCUMENT_OVERVIEW',
  route: ['', 'overview']
};
export const SINGLE_DOCUMENT_EDIT_TAGS = { name: 'SINGLE_DOCUMENT_EDIT_TAGS', route: 'edittags' };
export const SINGLE_DOCUMENT_EDIT_REMINDER = {
  name: 'SINGLE_DOCUMENT_EDIT_REMINDER',
  route: 'reminder'
};
export const SINGLE_DOCUMENT_EDIT_INFOS = {
  name: 'SINGLE_DOCUMENT_EDIT_INFOS',
  route: 'editinfos'
};
export const SINGLE_DOCUMENT_EDIT_CONTACT = {
  name: 'SINGLE_DOCUMENT_EDIT_CONTACT',
  route: 'editcontact'
};
export const SINGLE_DOCUMENT_SELECT_CONTACT = {
  name: 'SINGLE_DOCUMENT_SELECT_CONTACT',
  route: 'selectcontact'
};
export const SINGLE_DOCUMENT_SELECT_BOX = {
  name: 'SINGLE_DOCUMENT_SELECT_BOX',
  route: 'fileeebox'
};
export const SINGLE_DOCUMENT_SHARE = { name: 'SINGLE_DOCUMENT_SHARE', route: 'share' };
export const SINGLE_DOCUMENT_BEAHERO = { name: 'SINGLE_DOCUMENT_BEAHERO', route: 'send-to-fileee' };
export const SINGLE_DOCUMENT_EDIT_EXPIRY = {
  name: 'SINGLE_DOCUMENT_EDIT_EXPIRY',
  route: 'edit-expires'
};

export const SINGLE_DOCUMENT_SERVICES = {
  name: 'SINGLE_DOCUMENT_SERVICES',
  route: 'services/:serviceId'
};

export const ACCOUNT = { name: 'ACCOUNT', route: '/account' };
export const ACCOUNT_SETTINGS = { name: 'ACCOUNT_SETTINGS', route: 'settings' };
export const LINKED_SERVICES = { name: 'LINKED_SERVICES', route: 'linkedServices' };

export const FILEEEBOX_LIBRARY = { name: 'FILEEEBOX_LIBRARY', route: '/fileeebox' };
export const SINGLE_FILEEEBOX = { name: 'SINGLE_FILEEEBOX', route: 'fileeebox/:id' };
//TODO change during view implementation
export const TAG_LIBRARY = { name: 'TAG_LIBRARY', route: 'tags' };
export const BIN = { name: 'BIN', route: 'bin' };
export const COMPANY_LIBRARY = { name: 'COMPANY_LIBRARY', route: '/companies' };
export const SINGLE_COMPANY = { name: 'SINGLE_COMPANY', route: 'companies/:id' };

export const COMMUNICATION = { name: 'COMMUNICATION', route: '/communication' };
export const SINGLE_COMMUNICATION = { name: 'SINGLE_COMMUNICATION', route: 'communication/:id' };
export const SINGLE_COMMUNICATION_DOCUMENTS = {
  name: 'SINGLE_COMMUNICATION_DOCUMENTS',
  route: 'communication/:id/documents'
};

export const SINGLE_COMMUNICATION_INFO = { name: 'SINGLE_COMMUNICATION_INFO', route: ['info', ''] };
export const SINGLE_COMMUNICATION_TASKS = { name: 'SINGLE_COMMUNICATION_TASKS', route: 'tasks' };
export const SINGLE_COMMUNICATION_HISTORY = {
  name: 'SINGLE_COMMUNICATION_HISTORY',
  route: 'timeline'
};

export const SHARED_SPACES = { name: 'SHARED_SPACES', route: '/fileeeSpaces' };

export const EXTERNAL_COMMUNICATION_LANDING = {
  name: 'EXTERNAL_COMMUNICATION_LANDING',
  route: 'external/communication/:token'
};
export const EXTERNAL_COMMUNICATION = {
  name: 'EXTERNAL_COMMUNICATION',
  route: 'external/communication/:token/auth'
};
// export const EXTERNAL_COMMUNICATION_PIN = {name : 'EXTERNAL_COMMUNICATION_PIN', route : 'external/communication/:token/:id'}

export const REMINDERS = { name: 'REMINDERS', route: '/reminders' };

export const OAUTH = { name: 'OAUTH', route: 'oauth/authorize' };

export const USER_ACTIONS = {
  name: 'ACTIONS',
  route: 'actions',
  routerPath: 'actions/:actionId',
  startPath: '/actions/'
};

export const EMAIL_VERIFICATION = { name: 'EMAIL_VERIFICATION', route: 'resend-activation-mail' };

// BRANDED LOGIN/SIGNUP routes
export const FILEEE_LOGIN = { name: 'FILEEE_LOGIN', route: ['', 'login', 'signup'] };

// when user is trying to log in with gmx/webde, server redirects the user to this route. It should be not used for any other purposes
export const FILEEE_OPENID_LOGIN = { name: 'FILEEE_OPENID_LOGIN', route: 'openid/login' };
export const FILEEE_OPENID_ERROR = { name: 'FILEEE_OPENID_ERROR', route: 'openid/error' };

export const FILEEE_RESET_PASSWORD = { name: 'FILEEE_RESET_PASSWORD', route: 'resetpassword' };
export const FILEEE_SET_NEW_PASSWORD = {
  name: 'FILEEE_SET_NEW_PASSWORD',
  route: 'new-password/:token'
};
export const FILEEE_WELCOME = { name: 'FILEEE_WELCOME', route: 'welcome' };

// enterpirse related routes
export const ENTERPRISE = { name: 'ENTERPRISE', route: '/conversations-editor' };

export const O_AUTH_2 = { name: 'O_AUTH_2', route: 'oAuth2' };

// export const MESSAGE_BUNDLE_LIBRARY = {name: "MESSAGE_BUNDLE_LIBRARY", route: ['', 'messages']}
// export const MESSAGE_BUNDLE_SINGLE_MESSAGE = {name: "MESSAGE_BUNDLE_SINGLE_MESSAGE", route: 'messages/:id/message/:messageId'}

//module-container routes

export const MODULE_BASE = { name: 'MODULE_BASE', route: ['', 'module'] };

export const EXTERNAL_ACTION_CONVERSATIONS_LIBRARY = {
  routerPath: 'external/actions/:actionId/*',
  startPath: '/external/actions/',
  getUrlForAction: (actionId: string) => {
    return `/external/actions/${actionId}/conversations`;
  }
};
export const EXTERNAL_COMMUNICATION_BY_TOKEN = {
  routerPath: 'external/communication/*',
  startPath: '/external/communication'
};
